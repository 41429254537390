<template>
  <div>
    <v-btn color="primary" depressed @click="printPdf" v-text="$t('organization.statistics.exportStats')" />
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  name: 'ExportStats',
  methods: {
    printPdf() {
      const element = document.getElementById('statsView')
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        // arbitrary query for big screens, as the dom element is of fixed size, and jsPDF adapts the "format" to the size of the screen
        format: window.innerWidth > 1600 ? 'a2' : 'a1',
      })
      html2canvas(element).then(canvas => {
        const img = canvas.toDataURL('image/jpeg', 1)
        doc.addImage(img, 'JPEG', 0, 0)
        doc.save(`${this.$store.state.organization.dbData.name} - Statistics - ${new Date().toLocaleString()}.pdf`)
      })
    },
  },
}
</script>
